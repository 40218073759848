const ATTRIBUTE_PREFIX = 'data-ga-'

const attribute = suffix => `${ATTRIBUTE_PREFIX}${suffix}`

const fireGAEvent = (eventType, category, action, label = '') => {
  window.dataLayer.push({
    event: `dn.${eventType}`,
    event_params: {
      category,
      action,
      label
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(`[${attribute('event')}]`).forEach(element => {
    const eventType = element.getAttribute(attribute('event'))
    const category = element.getAttribute(attribute('event-category'))
    const label = element.getAttribute(attribute('event-label'))
    const fireEventOn = element.getAttribute(attribute('event-on')) || 'click'

    element.addEventListener(fireEventOn, () => {
      fireGAEvent(eventType, category, fireEventOn, label)
    })
  })
})
